import React from 'react';

import { LayoutFill } from '../layout-fill/LayoutFill';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';

import { Toolbar as LegacyToolbar, ToolbarProps as LegacyToolbarProps } from './legacy/Toolbar';
import { ToolbarItem as LegacyToolbarItem } from './legacy/ToolbarItem';
import { Toolbar as DefaultToolbar, ToolbarProps as DefaultToolbarProps } from './new/Toolbar';
import { ToolbarButton } from './new/ToolbarButton';

type LegacyToolbarPropsWithVariant = {
  variant: 'legacy';
} & LegacyToolbarProps;

type DefaultToolbarPropsWithVariant = {
  variant: 'default';
} & DefaultToolbarProps;

export type ToolbarProps = LegacyToolbarPropsWithVariant | DefaultToolbarPropsWithVariant;

const BaseToolbar = ({ variant, ...props }: ToolbarProps) => {
  if (variant === 'legacy') {
    return <LegacyToolbar {...props} />;
  }

  const props2: DefaultToolbarProps = props as DefaultToolbarProps; // typescript complains about typings from legacy variant

  return <DefaultToolbar {...props2} />;
};

export const Toolbar = Object.assign(BaseToolbar, {
  Button: ToolbarButton,
  /** @deprecated: this is only for variant="legacy" */
  Item: LegacyToolbarItem,
  Fill: LayoutFill,
  Separator: LayoutSeparator,
});
