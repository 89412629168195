export const featureFlags = [
  'withAdminPanel',

  'withSmoothingV2',

  'withRunGroupsV4',

  'withSingleRunAutoFollow',
  'withSectionsInDashboards',

  'withDynamicRegexpMetrics',
  'withMarkdownWidgetsDashboard',
  'withNewDownloadAsPng',
  'withReportDuplication',
  'withPresetColors',
  'withReportToolbarRedesign',
  'withShowMetricByColorOption',
] as const;
export type FeatureFlag = (typeof featureFlags)[number];

export type FeatureFlagConfig = `${FeatureFlag}Config`;
