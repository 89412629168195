// Libs
import React from 'react';

import { ButtonWithForwardedRef, DropdownToggleProps, Icon } from '@neptune/shared/venus-ui';

export const DotsHButton = React.forwardRef<HTMLButtonElement | null, DropdownToggleProps>(
  ({ onCollapse, onExpand, onToggle, size = 'lg', ...restProps }, ref) => {
    return (
      <ButtonWithForwardedRef
        size={size}
        variant="secondary-text"
        square
        onClick={onToggle}
        {...restProps}
        ref={ref}
      >
        <Icon glyph="dots-horizontal" />
      </ButtonWithForwardedRef>
    );
  },
);
