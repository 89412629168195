import * as React from 'react';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';

import './InputSelectButton.less';

const block = bemBlock('input-select-button');

type ButtonProps = {
  placeholder?: string;
  variant?: 'small' | 'medium' | 'large';
};

export const Button = React.forwardRef<
  React.ElementRef<'button'>,
  React.ComponentPropsWithoutRef<'button'> & ButtonProps
>(({ className, children, placeholder, variant, ...props }, ref) => (
  <button
    ref={ref}
    className={block({ extra: className, modifiers: { variant } })}
    {...props}
    data-placeholder={children ? undefined : 'true'}
  >
    {children ?? placeholder}
    <Icon glyph="chevron-down" className={block({ element: 'icon' })} />
  </button>
));
