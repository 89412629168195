import React from 'react';
import { debounce } from 'lodash';

import type { ViewportContext } from './ViewportContext';

type UseInViewportParams = {
  viewport: ViewportContext;
  debounceMs?: number;
  onShow?: () => unknown;
  onHide?: () => unknown;
  domContainer: HTMLElement | null;
};

export const useInViewport: (params: UseInViewportParams) => void = ({
  viewport,
  debounceMs = 100,
  onShow,
  onHide,
  domContainer,
}) => {
  const initialValueSet = React.useRef(false);

  React.useLayoutEffect(() => {
    if (!domContainer) {
      return;
    }

    const callback = (visible: boolean) => {
      if (visible) {
        onShow?.();
      } else {
        onHide?.();
      }
    };

    const debouncedCallback = debounce(callback, debounceMs);
    viewport.add(domContainer, (visible) => {
      if (initialValueSet.current) {
        debouncedCallback(visible);
      } else {
        callback(visible);
        initialValueSet.current = true;
      }
    });

    return () => {
      viewport.remove(domContainer);
      debouncedCallback.cancel();
    };
  }, [onShow, onHide, domContainer, viewport, debounceMs]);
};
