import React from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { bemBlock, Icon } from '@neptune/shared/venus-ui';

import { InputSize } from '../types';

import { SearchInputProps } from './types';

import './SearchInputIcon.less';

const block = bemBlock('search-input-icon');

const getSize = (size: InputSize | undefined): SizeProp => {
  switch (size) {
    case 'small':
      return 'sm';
    case 'medium':
      return '1x';
    case 'large':
      return 'xl';

    default:
      return '1x';
  }
};

export const SearchInputIcon: React.FC<Pick<SearchInputProps, 'className' | 'size' | 'glyph'>> = ({
  className,
  size,
  glyph = 'search',
}) => (
  <div
    className={block({
      extra: className,
      modifiers: {
        size,
      },
    })}
  >
    <Icon glyph={glyph} size={getSize(size)} />
  </div>
);
