import { escapeRegExp } from 'lodash';
import { RE2JS } from 're2js';

export const prepareRegexpSearchWithAnchors = (input: string): string => {
  const userInput = input.trim();

  if (!userInput) {
    return '.*';
  }

  return `.*(${userInput}).*`
    .replace(/^...\^/, '(') // handles user provided ^ at the beginning
    .replace(/\$...$/, ')'); // handles user provided $ at the end
};

export const convertRegexpSearchToFilter = (search?: string, exclude?: string) => {
  return search || exclude
    ? {
        mustMatchRegexes: search ? [search] : undefined,
        mustNotMatchRegexes: exclude ? [exclude] : undefined,
      }
    : undefined;
};

export const isValidRegExp = (regexp: string) => {
  try {
    RE2JS.compile(regexp);
    return true;
  } catch {
    return false;
  }
};

export const createRegExpEscaper = () => {
  function escape(value: string): string {
    return escapeRegExp(value);
  }

  // uses same set of special character to unescape as in lodash escapeRegExp
  // ref: https://github.com/lodash/lodash/blob/main/lodash.js#L153C2-L154C53 line 153
  function unescape(value: string): string {
    return value.replace(/\\([\\^$.*+?()[\]{}|])/g, '$1');
  }

  return {
    escape,
    unescape,
  };
};

export const regexpEscaper = createRegExpEscaper();
