import React from 'react';

import { bemBlock } from '../../modules/bem';
import { SimpleTooltip } from '../tooltip/SimpleTooltip';

import { Emblem } from './Emblem';

const block = bemBlock('emblem-tooltip');

const COLOR_BACKGROUND_BUTTON_PRIMARY_SUBDUED = '#B9BAFA';

type EmblemTooltipProps = {
  text: string;
  tooltip?: string;
};

export const EmblemTooltip: React.FC<EmblemTooltipProps> = ({ text, tooltip }) => {
  return (
    <SimpleTooltip content={tooltip} dataRole="external-tooltip">
      <Emblem
        text={text}
        size="sm"
        variant="light"
        color={COLOR_BACKGROUND_BUTTON_PRIMARY_SUBDUED}
        className={block('emblem')}
        disableNativeTooltip={!!tooltip}
        data-role="external-emblem"
      />
    </SimpleTooltip>
  );
};
