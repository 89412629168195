import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { ActionsMenuCheckItem } from './items/ActionsMenuCheckItem';
import { ActionsMenuItem } from './items/ActionsMenuItem';
import { ActionsMenuRadioItem } from './items/ActionsMenuRadioItem';
import { ActionsMenuSlotItem } from './items/ActionsMenuSlotItem';
import { ActionsMenuSubMenuItem } from './items/ActionsMenuSubMenuItem';
import { ActionsMenuSwitcherItem } from './items/ActionsMenuSwitcherItem';
import { ActionsMenuTabGroup } from './items/ActionsMenuTabGroup';
import { ActionsMenuTabItem } from './items/ActionsMenuTabItem';
import { ActionsMenuActionLink } from './ActionsMenuActionLink';
import { ActionsMenuContent } from './ActionsMenuContent';
import { ActionsMenuFooter } from './ActionsMenuFooter';
import { ActionsMenuGroup } from './ActionsMenuGroup';
import { ActionsMenuSeparator } from './ActionsMenuSeparator';
import { ActionsMenuSubContent } from './ActionsMenuSubContent';

const ActionsMenuRoot: React.FC<DropdownMenu.DropdownMenuProps> = (props) => {
  return <DropdownMenu.Root {...props} />;
};

export const ActionsMenu = Object.assign(ActionsMenuRoot, {
  Portal: DropdownMenu.Portal,
  Trigger: DropdownMenu.Trigger,
  Content: ActionsMenuContent,
  Sub: DropdownMenu.Sub,
  SubTrigger: DropdownMenu.SubTrigger,
  SubContent: ActionsMenuSubContent,
  Item: ActionsMenuItem,
  CheckItem: ActionsMenuCheckItem,
  RadioItem: ActionsMenuRadioItem,
  RadioGroup: DropdownMenu.RadioGroup,
  SwitcherItem: ActionsMenuSwitcherItem,
  SubMenuItem: ActionsMenuSubMenuItem,
  Slot: ActionsMenuSlotItem,
  Separator: ActionsMenuSeparator,
  Group: ActionsMenuGroup,
  TabGroup: ActionsMenuTabGroup,
  TabItem: ActionsMenuTabItem,
  Footer: ActionsMenuFooter,
  ActionLink: ActionsMenuActionLink,
});
