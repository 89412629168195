import React from 'react';

import { bemBlock, Icon } from '@neptune/shared/venus-ui';

import { useHold } from 'common/hooks/useHold';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

import './CopyToClipboard.less';

const NOTIFICATION_TIMEOUT = 2000;

type CopyToClipboardProps = {
  className?: string;
  text?: string;
  textId?: string; // @TODO deprecated
  getText?: () => Promise<string | undefined>;
  theme?: 'dark' | 'light';
  title?: string;
};

const block = bemBlock('copy-to-clipboard');

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  className,
  text,
  textId,
  getText,
  theme = 'light',
  title = 'Copy to clipboard',
}) => {
  const [loading, setLoading] = React.useState(false);
  const { activate, active: isNotificationActive } = useHold(NOTIFICATION_TIMEOUT);

  const handleCopy = React.useCallback(async () => {
    setLoading(true);
    const textToClipboard = text === undefined && getText ? await getText() : text;

    if (!textToClipboard) {
      setLoading(false);
      return;
    }

    await navigator.clipboard.writeText(textToClipboard);
    setLoading(false);
    activate();
  }, [getText, text, activate]);

  const copyButtonClasses = block({
    extra: className,
    modifiers: {
      active: isNotificationActive,
      theme,
    },
  });

  return (
    <span
      className={copyButtonClasses}
      onClick={handleCopy}
      data-heap-redact-attributes="data-clipboard-text"
      data-clipboard-text={text}
      data-text-id={textId}
      data-role="copy-to-clipboard"
      title={title}
    >
      <LoadingIndicator size="small" isVisible={loading} />
      {!loading ? <Icon className={block('icon')} glyph="clone" /> : undefined}
    </span>
  );
};
