import React from 'react';

import { RadioWithLabel, Toolbar } from '@neptune/shared/venus-ui';

export enum DisplayRows {
  All = 'All',
  Selected = 'Selected',
}

type InteractiveTableToolbarProps = {
  displayRows: DisplayRows;
  onDisplayRowsChange(displayRows: DisplayRows): void;
  paginationElement?: React.ReactElement;
};

export const InteractiveTableToolbar: React.FC<InteractiveTableToolbarProps> = ({
  displayRows,
  onDisplayRowsChange,
  paginationElement,
}) => {
  const handleDisplayRowsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.value === DisplayRows.All ? DisplayRows.All : DisplayRows.Selected;

      onDisplayRowsChange(newValue);
    },
    [onDisplayRowsChange],
  );

  return (
    <Toolbar
      variant="legacy"
      data-role="interactive-table-toolbar"
      overflow={['hidden', 'auto']}
      withCustomScrollbar
    >
      <Toolbar.Item label="Display rows">
        <RadioWithLabel
          id="display-all-rows"
          name="table-display-rows"
          label="All rows"
          checked={displayRows === DisplayRows.All}
          value={DisplayRows.All}
          onChange={handleDisplayRowsChange}
        />
        <RadioWithLabel
          id="display-selected-rows"
          name="table-display-rows"
          label="Only selected"
          checked={displayRows === DisplayRows.Selected}
          value={DisplayRows.Selected}
          onChange={handleDisplayRowsChange}
        />
      </Toolbar.Item>
      <Toolbar.Fill />
      {paginationElement && <Toolbar.Item children={paginationElement} />}
    </Toolbar>
  );
};
