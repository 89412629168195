export function parseText(text: string | undefined): Array<string> {
  if (text === undefined) {
    return [];
  }

  const lines = text.split(/\r\n|\r|\n/);
  const length = lines.length;

  // Omit last line if empty.
  if (lines[length - 1] === '') {
    lines.pop();
  }

  return lines;
}

export function escapeQuoteMarks(text: string): string {
  return text.replace(/(?<!\\)"/g, '\\"');
}
