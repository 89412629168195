import React from 'react';

import { bemBlock } from '../../../modules/bem';
import { Opaque, opaqueClassName } from '../../../modules/decorator';
import { LayoutFill } from '../../layout-fill/LayoutFill';
import { LayoutRow } from '../../layout-row/LayoutRow';
import { LayoutSeparator } from '../../layout-separator/LayoutSeparator';

import { ToolbarButton } from './ToolbarButton';

import './Toolbar.less';

const block = bemBlock('n-toolbar');

export type ToolbarProps = {
  className?: string;
  opaque?: Opaque;
  spacedChildren?: 'xs' | 'sm';
  withPadding?: 'none' | 'sm';
};

const BaseToolbar: React.FC<ToolbarProps> = ({
  children,
  className,
  opaque,
  spacedChildren = 'sm' as const,
  withPadding = 'sm' as const,
}) => {
  return (
    <LayoutRow
      className={block({
        extra: [className, opaqueClassName(opaque)],
      })}
      spacedChildren={spacedChildren}
      withPadding={withPadding}
      children={children}
    />
  );
};

export const Toolbar = Object.assign(BaseToolbar, {
  Button: ToolbarButton,
  Fill: LayoutFill,
  Separator: LayoutSeparator,
});
