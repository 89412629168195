/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorsConfigDTO } from './ColorsConfigDTO';
import {
    ColorsConfigDTOFromJSON,
    ColorsConfigDTOFromJSONTyped,
    ColorsConfigDTOToJSON,
} from './ColorsConfigDTO';
import type { RunGroupDTO } from './RunGroupDTO';
import {
    RunGroupDTOFromJSON,
    RunGroupDTOFromJSONTyped,
    RunGroupDTOToJSON,
} from './RunGroupDTO';
import type { WidgetLayoutDTO } from './WidgetLayoutDTO';
import {
    WidgetLayoutDTOFromJSON,
    WidgetLayoutDTOFromJSONTyped,
    WidgetLayoutDTOToJSON,
} from './WidgetLayoutDTO';
import type { DashboardConfigDTO } from './DashboardConfigDTO';
import {
    DashboardConfigDTOFromJSON,
    DashboardConfigDTOFromJSONTyped,
    DashboardConfigDTOToJSON,
} from './DashboardConfigDTO';
import type { WidgetDTO } from './WidgetDTO';
import {
    WidgetDTOFromJSON,
    WidgetDTOFromJSONTyped,
    WidgetDTOToJSON,
} from './WidgetDTO';

/**
 * 
 * @export
 * @interface ReportNodeGridDTO
 */
export interface ReportNodeGridDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ReportNodeGridDTO
     */
    aggregateByGroup: boolean;
    /**
     * 
     * @type {DashboardConfigDTO}
     * @memberof ReportNodeGridDTO
     */
    chartsConfig: DashboardConfigDTO;
    /**
     * 
     * @type {ColorsConfigDTO}
     * @memberof ReportNodeGridDTO
     */
    colorsConfig: ColorsConfigDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ReportNodeGridDTO
     */
    inheritRunColorFromGroup: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportNodeGridDTO
     */
    openRunGroups: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportNodeGridDTO
     */
    presetColors?: Array<string>;
    /**
     * 
     * @type {Array<RunGroupDTO>}
     * @memberof ReportNodeGridDTO
     */
    runGroups: Array<RunGroupDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportNodeGridDTO
     */
    runsLineage: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportNodeGridDTO
     */
    selectedRunGroups: Array<string>;
    /**
     * 
     * @type {Array<WidgetLayoutDTO>}
     * @memberof ReportNodeGridDTO
     */
    widgetLayouts: Array<WidgetLayoutDTO>;
    /**
     * 
     * @type {Array<WidgetDTO>}
     * @memberof ReportNodeGridDTO
     */
    widgets: Array<WidgetDTO>;
}

/**
 * Check if a given object implements the ReportNodeGridDTO interface.
 */
export function instanceOfReportNodeGridDTO(value: object): value is ReportNodeGridDTO {
    if (!('aggregateByGroup' in value) || value['aggregateByGroup'] === undefined) return false;
    if (!('chartsConfig' in value) || value['chartsConfig'] === undefined) return false;
    if (!('colorsConfig' in value) || value['colorsConfig'] === undefined) return false;
    if (!('inheritRunColorFromGroup' in value) || value['inheritRunColorFromGroup'] === undefined) return false;
    if (!('openRunGroups' in value) || value['openRunGroups'] === undefined) return false;
    if (!('runGroups' in value) || value['runGroups'] === undefined) return false;
    if (!('runsLineage' in value) || value['runsLineage'] === undefined) return false;
    if (!('selectedRunGroups' in value) || value['selectedRunGroups'] === undefined) return false;
    if (!('widgetLayouts' in value) || value['widgetLayouts'] === undefined) return false;
    if (!('widgets' in value) || value['widgets'] === undefined) return false;
    return true;
}

export function ReportNodeGridDTOFromJSON(json: any): ReportNodeGridDTO {
    return ReportNodeGridDTOFromJSONTyped(json, false);
}

export function ReportNodeGridDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportNodeGridDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'aggregateByGroup': json['aggregateByGroup'],
        'chartsConfig': DashboardConfigDTOFromJSON(json['chartsConfig']),
        'colorsConfig': ColorsConfigDTOFromJSON(json['colorsConfig']),
        'inheritRunColorFromGroup': json['inheritRunColorFromGroup'],
        'openRunGroups': json['openRunGroups'],
        'presetColors': json['presetColors'] == null ? undefined : json['presetColors'],
        'runGroups': ((json['runGroups'] as Array<any>).map(RunGroupDTOFromJSON)),
        'runsLineage': json['runsLineage'],
        'selectedRunGroups': json['selectedRunGroups'],
        'widgetLayouts': ((json['widgetLayouts'] as Array<any>).map(WidgetLayoutDTOFromJSON)),
        'widgets': ((json['widgets'] as Array<any>).map(WidgetDTOFromJSON)),
    };
}

export function ReportNodeGridDTOToJSON(value?: ReportNodeGridDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aggregateByGroup': value['aggregateByGroup'],
        'chartsConfig': DashboardConfigDTOToJSON(value['chartsConfig']),
        'colorsConfig': ColorsConfigDTOToJSON(value['colorsConfig']),
        'inheritRunColorFromGroup': value['inheritRunColorFromGroup'],
        'openRunGroups': value['openRunGroups'],
        'presetColors': value['presetColors'],
        'runGroups': ((value['runGroups'] as Array<any>).map(RunGroupDTOToJSON)),
        'runsLineage': value['runsLineage'],
        'selectedRunGroups': value['selectedRunGroups'],
        'widgetLayouts': ((value['widgetLayouts'] as Array<any>).map(WidgetLayoutDTOToJSON)),
        'widgets': ((value['widgets'] as Array<any>).map(WidgetDTOToJSON)),
    };
}

