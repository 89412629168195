import React from 'react';

import { endOfMatchMark, startOfMatchMark } from '@neptune/shared/common-domain';
import { TextProps } from '@neptune/shared/venus-ui';

import { ComputePartitionIndex } from '../domain/compute-partition-index';

import { MiddleEllipsisRenderer } from './MiddleEllipsisRenderer';
import { TextWithMatching } from './TextWithMatching';

type MiddleEllipsisWithMatchingProps = {
  text: string;
  match: string;
  id?: string;
  expandable?: boolean;
  'data-role'?: string;
  computePartitionIndex: ComputePartitionIndex;
} & Pick<
  TextProps<{}>,
  'size' | 'className' | 'fontFamily' | 'fontWeight' | 'lineHeight' | 'color'
>;

const markCharRe = new RegExp(`[${endOfMatchMark}${startOfMatchMark}]`);

export const MiddleEllipsisWithMatching = ({
  id,
  text,
  match,
  fontWeight,
  fontFamily,
  size,
  expandable,
  color,
  computePartitionIndex,
  lineHeight,
  className,
  'data-role': dataRole,
}: MiddleEllipsisWithMatchingProps) => {
  const partitionIndex = computePartitionIndex(match);
  const firstPart = match.substring(0, partitionIndex);
  const secondPart = match.substring(partitionIndex);
  const hasUnfinishedMarks = secondPart.match(markCharRe)?.[0] === endOfMatchMark;

  return (
    <MiddleEllipsisRenderer
      id={id}
      expandable={expandable}
      text={text}
      firstPart={
        <TextWithMatching text={`${firstPart}${hasUnfinishedMarks ? endOfMatchMark : ''}`} />
      }
      secondPart={
        <TextWithMatching text={`${hasUnfinishedMarks ? startOfMatchMark : ''}${secondPart}`} />
      }
      color={color}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      data-role={dataRole}
      className={className}
      lineHeight={lineHeight}
      size={size}
    />
  );
};
