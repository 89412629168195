import React from 'react';
import ReactDom from 'react-dom';
import { isNumber } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { ResizeAction } from 'neptune-core-ui/components/table/actions/ResizeAction';

import { ResizeEvent } from '@neptune/shared/leaderboard-domain';
import { bemBlock, Card, CardProps, Layout } from '@neptune/shared/venus-ui';

import './VerticalResizableCard.less';

const block = bemBlock('vertical-resizable-card');

type ResizableCardProps = {
  minHeight?: number;
  height?: number | string;
  width?: number | string;
  onResizeStart?: () => void;
  onResize?: (value: number) => void;
  onResizeEnd?: (value: number) => void;
} & CardProps;

export const VerticalResizableCard: React.FC<ResizableCardProps> = ({
  className,
  children,
  minHeight = 0,
  height: heightRaw,
  width,
  onResizeStart,
  onResize,
  onResizeEnd,
  ...rest
}) => {
  const [isResizing, setIsResizing] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement | undefined>(undefined);

  const handleResize = React.useCallback(
    (e: ResizeEvent) => {
      if (!isNumber(heightRaw)) {
        return;
      }

      const newValue = Math.max(heightRaw + e.diffY, minHeight);
      setIsResizing(true);

      if (containerRef.current) {
        containerRef.current.style.height = `${newValue}px`;
      }

      onResize?.(newValue);
    },
    [onResize, heightRaw, setIsResizing, minHeight],
  );

  const handleResizeEnd = React.useCallback(
    (e: ResizeEvent) => {
      if (!isNumber(heightRaw)) {
        return;
      }

      const newValue = Math.max(heightRaw + e.diffY, minHeight);
      setIsResizing(false);
      onResizeEnd?.(newValue);
    },
    [setIsResizing, onResizeEnd, minHeight, heightRaw],
  );

  return (
    <Card
      className={block({ extra: className })}
      height={heightRaw}
      width={width}
      overflow="hidden"
      withBoxShadow
      elementRef={containerRef}
      {...rest}
    >
      <Layout.Column span="shrink" height={heightRaw}>
        {children}
      </Layout.Column>
      {isNumber(heightRaw) && (
        <ResizeAction
          orientation="vertical"
          onResizeEnd={handleResizeEnd}
          onResize={handleResize}
          onResizeStart={onResizeStart}
          column={undefined}
        />
      )}
      {isResizing && ReactDom.createPortal(<div className={block('dim')} />, document.body)}
    </Card>
  );
};
