import React from 'react';
import { Link as Router5Link } from 'react-router5';

import { NormalizedLink, NormalizedLinkProps } from '@neptune/shared/routing-ui';
import { Button, Icon } from '@neptune/shared/venus-ui';

import { InfoTooltip } from '../info-tooltip/InfoTooltip';

type DistractionFreeButtonProps = Pick<
  React.ComponentProps<typeof Router5Link>,
  'routeName' | 'routeParams'
> & { icon: 'expand' | 'compress'; tooltipContent: string; 'data-role': string };

export const DistractionFreeButton: React.FC<DistractionFreeButtonProps> = ({
  icon,
  routeName,
  routeParams,
  tooltipContent,
  'data-role': dataRole,
}) => {
  return (
    <InfoTooltip
      text={
        <InfoTooltip.ContentWithKeyShortcut shortcut="Space">
          {tooltipContent}
        </InfoTooltip.ContentWithKeyShortcut>
      }
    >
      <Button<NormalizedLinkProps<{}>>
        square
        size="lg"
        data-role={dataRole}
        routeName={routeName}
        variant="secondary-text"
        routeParams={routeParams}
        component={NormalizedLink}
      >
        <Icon fixedWidth glyph={icon} />
      </Button>
    </InfoTooltip>
  );
};
