import React from 'react';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';
import { noop } from 'lodash';

import { useInViewport, ViewportContext } from 'components/in-viewport';

const VirtualizationWrapperContext = React.createContext<{
  isVisible: boolean;
  stopVirtualization: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isVisible: true,
  stopVirtualization: noop,
});

export function useVirtualizationWrapperIsVisible() {
  return React.useContext(VirtualizationWrapperContext).isVisible;
}

export function useVirtualizationWrapperContext() {
  return React.useContext(VirtualizationWrapperContext);
}

type VirtualizationWrapperProps = {
  children: React.ReactNode;
  className?: string;
  disableVirtualization?: boolean;
  visibleOverride?: boolean;
  placeholder: React.ReactNode;
};

export const VirtualizationWrapper: React.FC<VirtualizationWrapperProps> = ({
  children,
  disableVirtualization,
  ...restProps
}) => {
  if (disableVirtualization) {
    // Note: in case of nested VirtualizationWrappers this can result in slightly different
    // behavior then enabled path, as VirtualizationWrapperContext is inherited here.
    return <>{children}</>;
  }

  return <VirtualizationWrapperInner {...restProps} children={children} />;
};

const VirtualizationWrapperInner: React.FC<Omit<VirtualizationWrapperProps, 'active'>> = ({
  className = '',
  children,
  placeholder,
  visibleOverride,
}) => {
  const portalNode = React.useMemo(
    () =>
      createHtmlPortalNode({
        attributes: {
          class: className,
        },
      }),
    [className],
  );

  const [stopVirtualization, setStopVirtualization] = React.useState(false);

  const viewport = ViewportContext.useCreateViewportContext();
  const [inViewport, setInViewport] = React.useState<boolean | undefined>(undefined);
  const [domContainer, setDomContainer] = React.useState<HTMLElement | null>(null);
  const onShow = React.useCallback(() => setInViewport(true), []);
  const onHide = React.useCallback(() => setInViewport(false), []);

  useInViewport({
    viewport,
    debounceMs: 64,
    onShow,
    onHide,
    domContainer,
  });

  const visible = Boolean(stopVirtualization || (visibleOverride ?? inViewport));

  const childrenRenderedRef = React.useRef(false);
  const renderChildren = childrenRenderedRef.current || visible;
  childrenRenderedRef.current = renderChildren;

  const contextValue = React.useMemo(
    () =>
      visible !== undefined
        ? {
            isVisible: visible,
            stopVirtualization: setStopVirtualization,
          }
        : undefined,
    [visible],
  );

  return (
    <div className={className} ref={setDomContainer}>
      {/* It is crucial that OutPortal is rendered before InPortal, so that children
          are rendered into DOM and not a detached node. */}
      {visible ? <OutPortal node={portalNode} visible /> : placeholder}
      {contextValue && (
        <VirtualizationWrapperContext.Provider value={contextValue}>
          {inViewport !== undefined && (
            <InPortal node={portalNode} children={renderChildren ? children : null} />
          )}
        </VirtualizationWrapperContext.Provider>
      )}
    </div>
  );
};
