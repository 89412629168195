import React from 'react';

import { DropdownToggleInterface } from '@neptune/shared/venus-domain';
import { Dropdown } from '@neptune/shared/venus-ui';

import { getPresetColorsEnabled } from 'common/featureFlag';

import { ColorPicker } from './ColorPicker';

type ColorPickerDropdownProps = {
  label?: string;
  color?: string;
  className?: string;
  presetColors?: string[];
  toggle?: React.ReactElement<DropdownToggleInterface> | string;
  dataRole?: string;
  onChange?: (color: string) => void;
  onAddPresetColor?: (color: string) => void;
};

export function ColorPickerDropdown({
  className,
  dataRole,
  color,
  label = 'Change color',
  toggle,
  presetColors,
  onChange,
  onAddPresetColor,
}: ColorPickerDropdownProps) {
  const originalColorRef = React.useRef(color);
  const currentColorRef = React.useRef(color);

  const handleExpand = React.useCallback(() => {
    originalColorRef.current = color;
  }, [color]);

  const handleCollapse = React.useCallback(() => {
    if (
      currentColorRef.current &&
      currentColorRef.current !== originalColorRef.current &&
      getPresetColorsEnabled()
    ) {
      onAddPresetColor?.(currentColorRef.current);
    }
  }, [onAddPresetColor]);

  const restoreColor = React.useCallback(() => {
    if (!originalColorRef.current) {
      return;
    }

    currentColorRef.current = originalColorRef.current;

    onChange?.(originalColorRef.current);
  }, [onChange]);

  const handleOnChange = React.useCallback(
    (newColor: string) => {
      currentColorRef.current = newColor;
      onChange?.(newColor);
    },
    [onChange],
  );

  const renderToggle = () => {
    if (toggle) {
      return toggle;
    }

    return (
      <Dropdown.SubmenuToggle
        icon="color"
        label={label}
        disableChevron
        component="label"
        data-role={dataRole}
      />
    );
  };

  return (
    <Dropdown
      toggle={renderToggle()}
      onExpand={handleExpand}
      onCollapse={handleCollapse}
      attachment="top left"
      targetAttachment="top right"
      constraints={Dropdown.CONSTRAINT_TO_WINDOW}
    >
      <Dropdown.Menu withPadding="none">
        <ColorPicker
          className={className}
          defaultValue={color}
          onChange={handleOnChange}
          onCancel={restoreColor}
          presetColors={presetColors}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
