import React from 'react';

export function useHold(timeout: number) {
  const [timeoutId, setTimeoutId] = React.useState<ReturnType<typeof setTimeout>>();

  const activate = React.useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => setTimeoutId(undefined), timeout);
    setTimeoutId(newTimeoutId);
  }, [timeout, timeoutId, setTimeoutId]);

  const active = !!timeoutId;
  return { activate, active };
}
