import React from 'react';

type ViewportCallback = (visible: boolean) => unknown;

function initializeViewportContext() {
  return new ViewportContext();
}

const useCreateViewportContext = () => {
  const [viewportContext] = React.useState(initializeViewportContext);

  React.useEffect(() => {
    return () => viewportContext.destroy();
  }, [viewportContext]);

  return viewportContext;
};

export class ViewportContext {
  private readonly mapping = new Map<Element, { callback: ViewportCallback; last: boolean }>();
  private readonly observer: IntersectionObserver;
  private readonly observerCallback: (entries: IntersectionObserverEntry[]) => void;

  static useCreateViewportContext = useCreateViewportContext;

  constructor(rootMargin?: string, root?: Element | Document | null) {
    this.observerCallback = (entries) => {
      entries.forEach((entry) => {
        const data = this.mapping.get(entry.target);

        if (!data) {
          return;
        }

        if (data.last !== entry.isIntersecting) {
          data.callback?.call(null, entry.isIntersecting);
          data.last = entry.isIntersecting;
        }
      });
    };

    this.observer = new IntersectionObserver(this.observerCallback, { rootMargin, root });
  }

  destroy() {
    this.mapping.clear();
    this.observer.disconnect();
  }

  add(element: Element, callback: ViewportCallback) {
    this.mapping.set(element, { callback, last: false });
    this.observer.observe(element);
    // Call the callback immediately, in synchronous fashion.
    this.observerCallback(this.observer.takeRecords());
  }

  remove(element: Element) {
    this.mapping.delete(element);
    this.observer.unobserve(element);
  }
}
