import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { bemBlock } from '../../../modules/bem';
import { LayoutColumn } from '../../layout-column/LayoutColumn';
import { Text } from '../../text/Text';

import './ActionsMenuTabItem.less';

const block = bemBlock('n-actions-menu-tab-item');

type TabItemOwnProps = {
  leadingIcon?: React.ReactNode;
};

type TabItemProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.RadioItem> & TabItemOwnProps;

export const ActionsMenuTabItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.RadioItem>,
  TabItemProps
>(({ className, children, leadingIcon, disabled, ...props }, ref) => {
  return (
    <DropdownMenu.RadioItem
      ref={ref}
      className={block({
        extra: className,
      })}
      disabled={disabled}
      {...props}
    >
      <LayoutColumn
        span="greedy"
        alignItems="center"
        spacedChildren="xs"
        height={52}
        justifyContent="center"
      >
        {leadingIcon}
        <Text size="sm">{children}</Text>
      </LayoutColumn>
    </DropdownMenu.RadioItem>
  );
});
